import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CrudService } from '@services/laravel/crud.service';
import { ValidateCpf } from '@shared/validators/cpf.validator';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';
import { RegisterComponent } from './../../../../../../src/app/components/register/register.component';

@Component({
  selector: 'app-association-user-social',
  templateUrl: './association-user-social.component.html',
  styleUrls: ['./association-user-social.component.css'],
})
export class AssociationUserSocialComponent {
  public associationForm: UntypedFormGroup;
  public mask = {
    cpf: [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<AssociationUserSocialComponent>,
    private dialog: MatDialog,
    private crud: CrudService,
    private toastr: ToastrService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.associationForm = new UntypedFormGroup({
      cpf_number: new UntypedFormControl(null, [ValidateCpf, ValidateRequired]),
      password: new UntypedFormControl(null, [ValidateRequired]),
    });
  }

  association() {
    let obj = {
      cpf_number: this.associationForm.value.cpf_number,
      password: this.associationForm.value.password,
      email: this.data.email,
      google_id: this.data.id,
    };
    this.crud
      .post('associate-googleid', obj)
      .then((res: any) => {
        this.dialogRef.close();

        sessionStorage.setItem('access_token', 'Bearer ' + res.access_token);
        sessionStorage.setItem('application', JSON.stringify(res.application));

        this.toastr.success('Login feito com sucesso.', 'Bem-vindo!');

        this._router.navigate(['/main/select-application']);
      })
      .catch((rej: any) => {
        if (rej.status == 404) {
          this.dialogRef.close();
          return this.openDialog();
        }
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '80%',
      height: 'auto%',
      data: Object.assign({}, this.data, this.associationForm.value),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  close = () => {
    this.dialogRef.close();
  };
}
