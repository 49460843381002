import Quill from 'quill';

const BaseImage = Quill.import('formats/image') as any;

const ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'style'
];

const WHITE_STYLE = ['margin', 'display', 'float', 'width', 'height', 'alt'];

export class CustomImage extends BaseImage {
  // Sobrescreve o método que extrai os formatos do nó
  static formats(domNode: any): any {
    return ATTRIBUTES.reduce((formats: any, attribute: string) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  // Sobrescreve o método format para lidar com os atributos customizados
  format(name: string, value: any): void {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        if (name === 'style') {
          value = this.sanitizeStyle(value);
        }
        this['domNode'].setAttribute(name, value);
      } else {
        this['domNode'].removeAttribute(name);
      }
    } else {
      // Para os demais, chama o comportamento padrão
      super.format(name, value);
    }
  }

  // Filtra apenas as propriedades de estilo permitidas
  sanitizeStyle(style: string): string {
    const styleArr = style.split(';');
    let allowedStyle = "";
    styleArr.forEach((v: string) => {
      const parts = v.trim().split(':');
      if (parts.length === 2) {
        const key = parts[0].trim();
        const val = parts[1].trim();
        if (WHITE_STYLE.indexOf(key) !== -1) {
          allowedStyle += `${key}:${val};`;
        }
      }
    });
    return allowedStyle;
  }
}

// Registra o blot customizado para substituir o formato padrão de imagem
